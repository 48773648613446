import React, { useState } from "react";
import { Layout, Menu } from "antd";
import styled from "styled-components";
import { MdOutlineAdminPanelSettings } from "react-icons/md";
import {
	RiDashboard2Line,
	RiShoppingBasketLine,
	RiAddFill,
} from "react-icons/ri";
import { BiCategoryAlt } from "react-icons/bi";
import { IoIosCart } from "react-icons/io";
import { FiUsers } from "react-icons/fi";
import { BsImage } from "react-icons/bs";
import { GrOverview } from "react-icons/gr";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import Dashboard from "./Dashboard";
import AddProduct from "./AddProduct";
import ViewProducts from "./ViewProducts";
const { Header, Content, Sider } = Layout;

function MainPage() {
	const [collapsed, setCollapsed] = useState(false);
	const [isActive, setIsActive] = useState("1");
	const items = [
		getItem("Dashboard", "1", <RiDashboard2Line className="icon" />),
		getItem("Products", "2", <RiShoppingBasketLine className="icon" />, [
			getItem("Add Products", "2.1", <RiAddFill />),
			getItem("View All Products", "2.2", <GrOverview />),
		]),
		getItem("Categories", "3", <BiCategoryAlt className="icon" />),
		getItem("Orders", "4", <IoIosCart className="icon" />),
		getItem("Users", "5", <FiUsers className="icon" />),
		getItem("Banners", "6", <BsImage className="icon" />),
	];
	function getItem(label, key, icon, children) {
		return {
			key,
			icon,
			children,
			label,
		};
	}
	return (
		<Layout>
			<MainHeader>
				<div>
					<MdOutlineAdminPanelSettings />
					<span>Admin Panel</span>
				</div>
				{React.createElement(
					collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
					{
						className: "trigger",
						onClick: () => setCollapsed(!collapsed),
					}
				)}
			</MainHeader>
			<Layout>
				<MainSider
					collapsible
					collapsed={collapsed}
					trigger={null}
					style={{ backgroundColor: "#ededed" }}
					collapsedWidth="50"
					width="150"
				>
					<Menu
						mode="inline"
						defaultSelectedKeys={["1"]}
						inlineIndent={3}
						onSelect={({ key }) => setIsActive(key)}
						items={items}
					/>
				</MainSider>
				<MainContent>
					{isActive === "1" && <Dashboard />}
					{isActive === "2.1" && <AddProduct />}
					{isActive === "2.2" && <ViewProducts />}
				</MainContent>
			</Layout>
		</Layout>
	);
}

const MainHeader = styled(Header)`
	background-color: #fff;
	font-size: 20px;
	padding-left: 0;
	height: 80px;
	display: flex;
	flex-direction: column;
	gap: 0;
	align-items: flex-start;
	div {
		display: flex;
		align-items: center;
		gap: 10px;
		margin-top: -10px;
		color: #1890ff;
	}
	.trigger {
		cursor: pointer;
		transition: color 0.3s;
	}
	.trigger:hover {
		color: #1890ff;
	}
`;

const MainSider = styled(Sider)`
	background-color: #fff !important;
	.icon {
		font-size: 20px !important;
	}
	.item {
		display: flex;
		align-items: center;
	}
`;

const MainContent = styled(Content)`
	padding: 20px 20px 0 20px;
	min-height: 75vh;
`;

export default MainPage;
