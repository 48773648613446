import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { BsUpload } from "react-icons/bs";
import url from "../ServerConnection/config";
import axios from "axios";
import Loader from "./Loader";
import {
	Flex,
	Text,
	Alert,
	AlertIcon,
	AlertDescription,
	CloseButton,
} from "@chakra-ui/react";
import ScrollToTop from "./ScrollToTop";

function AddProduct() {
	const [productName, setProductName] = useState("");
	const [productDesc, setProductDesc] = useState("");
	const [productPrice, setProductPrice] = useState(0);
	const [productImg, setProductImg] = useState("");
	const [sku, setSku] = useState("");
	const [category, setCategory] = useState("");
	const [stockQuantity, setStockQuantity] = useState(0);
	const [warranty, setWarranty] = useState("");
	const [error, setError] = useState("");
	const [notification, setNotification] = useState("");
	const [categories, setCategories] = useState([]);
	const [submitting, setSubmitting] = useState(false);

	useEffect(() => {
		axios
			.get(`${url}/categories/all`)
			.then((res) => {
				setCategories(res.data);
			})
			.catch((err) => {
				console.log(err);
			});
	}, []);

	const types = ["image/png", "image/jpg", "image/jpeg"];

	const handleImg = (e) => {
		let selectedFile = e.target.files[0];
		if (selectedFile && types.includes(selectedFile.type)) {
			setProductImg(selectedFile);
			setError("");
		} else {
			setProductImg("");
			setError("Only images of types png, jpg, and jpeg allowed!");
		}
	};

	const handleForm = (e) => {
		e.preventDefault();
		<ScrollToTop />;
		const formData = new FormData();
		formData.append("file", productImg);
		formData.append("upload_preset", "gltldwfq");

		if (productImg === "") {
			setError("You must upload a valid image!");
			return;
		}

		setSubmitting(true);

		axios
			.post(
				"https://api.cloudinary.com/v1_1/hush-computing/image/upload",
				formData
			)
			.then((res) => {
				axios
					.post(`${url}/admin/products/add`, {
						name: productName,
						description: productDesc,
						price: productPrice,
						image: res.data.url,
						sku: sku,
						stockQuantity: stockQuantity,
						category: category,
						warranty: warranty,
					})
					.then(() => {
						setSubmitting(false);
						setNotification("Successfully added product " + productName);
						setProductName("");
						setProductDesc("");
						setProductPrice(0);
						setProductImg("");
						setSku("");
						setStockQuantity(0);
						setCategory("");
						setWarranty("");
						setError("");
					})
					.catch((err) => {
						setSubmitting(false);
						setError(err.response.data);
					});
			})
			.catch((err) => {
				setSubmitting(false);
				setError(err.response.data.error.message);
			});
	};

	return (
		<Product>
			{submitting ? (
				<Loader />
			) : (
				<>
					<Text mb="10px" fontSize="large" fontWeight="700">
						Add Product
					</Text>
					{error !== "" && (
						<Flex alignItems="center" position="relative" mb="10px">
							<Alert status="error" w="100%">
								<AlertIcon />
								<AlertDescription>{error}</AlertDescription>
							</Alert>
							<CloseButton
								alignSelf="center"
								position="absolute"
								right={1}
								onClick={() => setError("")}
							/>
						</Flex>
					)}
					{notification !== "" && (
						<Flex alignItems="center" position="relative" mb="10px">
							<Alert status="success" w="100%">
								<AlertIcon />
								<AlertDescription>{notification}</AlertDescription>
							</Alert>
							<CloseButton
								alignSelf="center"
								position="absolute"
								right={1}
								onClick={() => setNotification("")}
							/>
						</Flex>
					)}
					<div className="container">
						<Form onSubmit={handleForm}>
							<label htmlFor="name">Product Name</label>
							<input
								type="text"
								value={productName}
								onChange={(e) => setProductName(e.target.value)}
								required
							/>
							<label htmlFor="description">Description</label>
							<textarea
								type="text"
								value={productDesc}
								onChange={(e) => setProductDesc(e.target.value)}
								style={{ height: "100px" }}
								maxLength="1000"
								required
							/>
							<label htmlFor="price">Price (in Ksh.)</label>
							<input
								type="number"
								value={productPrice}
								onChange={(e) => setProductPrice(e.target.value)}
								required
							/>
							<label htmlFor="sku">SKU</label>
							<input
								type="text"
								value={sku}
								onChange={(e) => setSku(e.target.value)}
								required
							/>
							<label htmlFor="category">Category</label>
							<select
								type="text"
								value={category}
								onChange={(e) => setCategory(e.target.value)}
								required
							>
								<option value=""></option>
								{categories?.map((category) => {
									return (
										<option value={category.category} key={category.id}>
											{category.category}
										</option>
									);
								})}
							</select>
							<label htmlFor="stockQuantity">Stock Quantity</label>
							<input
								type="number"
								value={stockQuantity}
								onChange={(e) => setStockQuantity(e.target.value)}
								required
							/>
							<label htmlFor="warranty">Warranty Period</label>
							<input
								type="text"
								value={warranty}
								onChange={(e) => setWarranty(e.target.value)}
								required
							/>
							<label htmlFor="image">Image</label>
							<label htmlFor="file">
								<UploadBtn htmlFor="file" />
							</label>
							<input
								type="file"
								onChange={(e) => handleImg(e)}
								style={{ display: "none" }}
								id="file"
							/>
							<button type="submit">Add Product</button>
						</Form>
						<Imgdiv>
							<img
								src={
									productImg
										? URL.createObjectURL(productImg)
										: "https://media.istockphoto.com/vectors/no-image-available-sign-vector-id922962354?k=20&m=922962354&s=612x612&w=0&h=f-9tPXlFXtz9vg_-WonCXKCdBuPUevOBkp3DQ-i0xqo="
								}
								alt=""
							/>
						</Imgdiv>
					</div>
					<ScrollToTop />
				</>
			)}
		</Product>
	);
}

const Product = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	width: 100%;
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	.container {
		display: flex;
		gap: 20px;
		align-items: flex-start;
		margin-bottom: 20px;
		@media (max-width: 768px) {
			flex-direction: column;
		}
	}
`;

const Imgdiv = styled.div`
	width: 35%;
	display: flex;
	align-items: center;
	img {
		width: 100%;
		height: auto;
		margin-right: auto;
		margin-left: auto;
		object-fit: cover;
		cursor: pointer;
	}
	@media (max-width: 768px) {
		width: 100%;
	}
`;

const Form = styled.form`
	display: flex;
	flex-direction: column;
	gap: 5px;
	justify-content: center;
	width: 60%;
	padding-left: 10px;
	label {
		font-weight: 500;
	}
	input,
	select,
	textarea {
		margin-bottom: 5px;
		width: 70%;
		border: none;
		outline: none;
		background: white;
		color: black;
		font-size: 15px;
		border-radius: 2px;
		-webkit-box-shadow: 0px 2px 5px 2px rgba(118, 118, 118, 0.3);
		box-shadow: 0px 2px 5px 2px rgba(118, 118, 118, 0.3);
	}
	button {
		width: 70%;
		background-color: #418914;
		color: white;
		border: 1px solid;
		border-color: #418914;
		border-radius: 3px;
		transition: 450ms;
		margin-top: 15px;
		:hover,
		:focus {
			transform: scale(1.05);
		}
	}
	@media (max-width: 768px) {
		width: 100%;
		input,
		select,
		textarea {
			width: 95%;
		}
	}
`;

const UploadBtn = styled(BsUpload)`
	cursor: pointer;
	font-size: 20px;
`;

export default AddProduct;
