import React, { useEffect, useState } from "react";
import {
	Flex,
	Box,
	Text,
	Alert,
	AlertIcon,
	AlertDescription,
	CloseButton,
	Image,
	Button,
	Tooltip,
	Modal,
	ModalOverlay,
	ModalHeader,
	ModalContent,
	ModalBody,
	ModalFooter,
	AlertTitle,
	Spinner,
	ModalCloseButton,
	FormControl,
	FormLabel,
} from "@chakra-ui/react";
import axios from "axios";
import url from "../ServerConnection/config";
import Loader from "./Loader";
import { RiDeleteBinLine } from "react-icons/ri";
import { MdEdit } from "react-icons/md";
import { BsUpload } from "react-icons/bs";
import moment from "moment";
import { BiSearchAlt2 } from "react-icons/bi";

function ViewProducts() {
	const [searchWord, setSearchWord] = useState("");
	const [searchBtn, setSearchBtn] = useState("");
	const [searchResults, setSearchResults] = useState([]);
	const [products, setProducts] = useState([]);
	const [loading, setLoading] = useState(true);
	const [msg, setMsg] = useState("Load More Products");
	const [error, setError] = useState("");
	const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
	const [productToDelete, setProductToDelete] = useState({});
	const [alertInfo, setAlertInfo] = useState(false);
	const [submitting, setSubmitting] = useState(false);
	const [isOpenDetailsModal, setIsOpenDetailsModal] = useState(false);
	const [productToShow, setProductToShow] = useState({});
	const [sortBy, setSortBy] = useState("DESC");
	const [categories, setCategories] = useState([]);
	const [category, setCategory] = useState("All");
	const [productToEdit, setProductToEdit] = useState({});
	const [isOpenEditModal, setIsOpenEditModal] = useState(false);
	const [productImg, setProductImg] = useState("");
	const [editError, setEditError] = useState("");
	const [alertEditInfo, setAlertEditInfo] = useState(false);

	const types = ["image/png", "image/jpg", "image/jpeg"];

	const handleImg = (e) => {
		let selectedFile = e.target.files[0];
		if (selectedFile && types.includes(selectedFile.type)) {
			setProductImg(selectedFile);
			setEditError("");
		} else {
			setProductImg("");
			setEditError("Only images of types png, jpg, and jpeg allowed!");
		}
	};

	useEffect(() => {
		axios
			.get(`${url}/categories/all`)
			.then((res) => {
				setCategories(res.data);
			})
			.catch((err) => {
				console.log(err);
			});
	}, []);

	useEffect(() => {
		if (loading) {
			axios
				.post(`${url}/admin/products/allproducts?offset=${products.length}`, {
					sortBy: sortBy,
					category: category,
				})
				.then((res) => {
					if (res.data.length === 8) {
						const tempProducts = products.concat(res.data);
						setProducts(tempProducts);
						setLoading(false);
						setMsg("Load More Products");
					} else if (res.data.length > 0 && res.data.length < 8) {
						const tempProducts = products.concat(res.data);
						setProducts(tempProducts);
						setMsg("");
						setLoading(false);
					} else {
						setMsg("");
						setLoading(false);
					}
				})
				.catch((err) => {
					setError(err.response.data);
					setLoading(false);
				});
		}
	}, [loading, products, sortBy, category]);

	useEffect(() => {
		if (searchWord !== "") {
			axios
				.get(`${url}/search/products?search=${searchWord}`)
				.then((res) => {
					setError("");
					setSearchResults(res.data);
				})
				.catch(() => {
					setError("No results found. Try different words!");
					setSearchResults([]);
				});
		} else {
			setSearchResults([]);
			setError("");
			setCategory("All");
			setSortBy("DESC");
			setProducts([]);
			setLoading(true);
		}
	}, [searchWord, searchBtn]);

	const handleDeleteClick = (productId, productName, productImage) => {
		setIsOpenDeleteModal(true);
		setProductToDelete({
			productId: productId,
			productName: productName,
			productImage: productImage,
		});
	};

	const handleConfirmDelete = () => {
		setSubmitting(true);
		axios
			.post(`${url}/admin/products/delete`, {
				productId: productToDelete.productId,
				productImage: productToDelete.productImage,
			})
			.then(() => {
				const newProductsArray = products.filter(
					(product) => product.id !== productToDelete.productId
				);
				setProducts(newProductsArray);
				setAlertInfo(true);
				setSubmitting(false);
			})
			.catch((err) => {
				setError(err.response.data);
				setIsOpenDeleteModal(false);
				setAlertInfo(false);
				setProductToDelete({});
				setSubmitting(false);
			});
	};

	const handleEditClick = (product) => {
		setProductToEdit(product);
		setIsOpenEditModal(true);
	};

	const handleConfirmEdit = () => {
		if (
			!productToEdit.name ||
			!productToEdit.description ||
			!productToEdit.category ||
			!productToEdit.price ||
			!productToEdit.sku ||
			!productToEdit.stockQuantity ||
			!productToEdit.warranty
		) {
			setEditError("Fill in all fields!");
		} else {
			setSubmitting(true);
			if (productImg !== "") {
				const formData = new FormData();
				formData.append("file", productImg);
				formData.append("upload_preset", "gltldwfq");
				axios
					.post(
						"https://api.cloudinary.com/v1_1/hush-computing/image/upload",
						formData
					)
					.then((res) => {
						axios
							.post(`${url}/admin/products/edit`, {
								productToEdit,
								newProductImage: res.data.url,
							})
							.then(() => {
								setSubmitting(false);
								setEditError("");
								setAlertEditInfo(true);
								setLoading(true);
								setProducts([]);
							})
							.catch((err) => {
								setSubmitting(false);
								setEditError(err.response.data);
							});
					})
					.catch((err) => {
						setSubmitting(false);
						setEditError(err.response.data.error.message);
					});
			} else {
				axios
					.post(`${url}/admin/products/edit`, {
						productToEdit,
						newProductImage: "",
					})
					.then(() => {
						setSubmitting(false);
						setEditError("");
						setAlertEditInfo(true);
						setLoading(true);
						setProducts([]);
					})
					.catch((err) => {
						setSubmitting(false);
						setEditError(err.response.data);
					});
			}
		}
	};

	return (
		<>
			<Flex
				justifyContent="space-between"
				direction={{ base: "column", md: "row" }}
			>
				<Text mb="10px" fontSize="large" fontWeight="700">
					All Products
				</Text>
				<Flex alignItems="center" direction={{ base: "column", md: "row" }}>
					<Flex
						mr={{ base: 0, md: "10px" }}
						gap="10px"
						direction={{ base: "column", md: "row" }}
					>
						<select
							value={category}
							onChange={(e) => {
								setProducts([]);
								setCategory(e.target.value);
								setLoading(true);
							}}
							style={{ outline: "none", width: "100px" }}
						>
							<option value="All">All Categories</option>
							{categories?.map((category) => {
								return (
									<option value={category.category} key={category.id}>
										{category.category}
									</option>
								);
							})}
						</select>
						<select
							value={sortBy}
							onChange={(e) => {
								setProducts([]);
								setSortBy(e.target.value);
								setLoading(true);
							}}
							style={{ outline: "none", width: "100px" }}
						>
							<option value="DESC">Newest to Oldest</option>
							<option value="ASC">Oldest to Newest</option>
						</select>
					</Flex>
					<Flex
						position="relative"
						mt={{ base: "10px", md: 0 }}
						mb={{ base: "10px", md: 0 }}
						alignItems="center"
					>
						<input
							style={{
								width: "100%",
								height: "30px",
								border: "1px solid rgb(211,211,211)",
								borderRight: "none",
								borderTopLeftRadius: "3px",
								borderBottomLeftRadius: "3px",
								background: "#fff",
								outline: "none",
							}}
							value={searchWord}
							onChange={(e) => {
								setSearchWord(e.target.value);
							}}
						/>
						{searchWord !== "" && (
							<button
								onClick={() => {
									setSearchWord("");
								}}
								style={{
									position: "absolute",
									right: "33px",
									border: "none",
									outline: "none",
									backgroundColor: "white",
								}}
							>
								X
							</button>
						)}
						<BiSearchAlt2
							onClick={() => setSearchBtn(searchWord)}
							style={{
								border: "1px solid rgb(211,211,211)",
								borderTopRightRadius: "3px",
								borderBottomRightRadius: "3px",
								height: "30px",
								width: "30px",
								background: "#fff",
								cursor: "pointer",
								color: "#767676",
							}}
						/>
					</Flex>
				</Flex>
			</Flex>
			{error !== "" && (
				<Flex alignItems="center" position="relative" mb="10px">
					<Alert status="error" w="100%">
						<AlertIcon />
						<AlertDescription>{error}</AlertDescription>
					</Alert>
					<CloseButton
						alignSelf="center"
						position="absolute"
						right={1}
						onClick={() => setError("")}
					/>
				</Flex>
			)}
			{searchResults?.length > 0 ? (
				<Flex
					flexWrap="wrap"
					gap="10px"
					justifyContent="center"
					pb="20px"
					w="100%"
				>
					{searchResults?.map((product) => {
						return (
							<Box
								h="220px"
								flexBasis={{ base: "100%", sm: "48%", md: "48%", lg: "24%" }}
								flexGrow="1"
								flexShrink="1"
								boxShadow="-1px -1px 8px 1px rgba(211,211,211,0.68)"
								bgColor="#fff"
								p="3px"
								borderRadius="3px"
								key={product?.id}
							>
								<Box
									h="60%"
									onClick={() => {
										setIsOpenDetailsModal(true);
										setProductToShow(product);
									}}
									cursor="pointer"
								>
									<Image
										src={product?.image}
										alt=""
										w="100%"
										h="auto"
										maxHeight="100%"
										objectFit="contain"
									/>
								</Box>
								<Box h="40%" textAlign="center">
									<Text noOfLines={1} fontWeight="600" fontSize="small">
										{product?.name}
									</Text>
									<Text noOfLines={1} fontSize="small">
										KES. {product?.price.toLocaleString()}
									</Text>
									<Text noOfLines={1} fontSize="small">
										{product?.stockQuantity} in Stock
									</Text>
									<Flex gap="10px" justifyContent="center">
										<Tooltip label="Edit" hasArrow>
											<Button
												w="50px"
												h="20px"
												bgColor="#fff"
												onClick={() => handleEditClick(product)}
											>
												<MdEdit color="#418914" height="20px" />
											</Button>
										</Tooltip>
										<Tooltip label="Delete" hasArrow>
											<Button
												w="50px"
												h="20px"
												bgColor="#fff"
												onClick={() =>
													handleDeleteClick(
														product?.id,
														product?.name,
														product?.image
													)
												}
											>
												<RiDeleteBinLine color="#e74c3c" height="20px" />
											</Button>
										</Tooltip>
									</Flex>
								</Box>
							</Box>
						);
					})}
				</Flex>
			) : (
				<>
					<Flex
						flexWrap="wrap"
						gap="10px"
						justifyContent="center"
						pb="20px"
						w="100%"
					>
						{products?.length > 0 &&
							products?.map((product) => {
								return (
									<Box
										h="220px"
										flexBasis={{
											base: "100%",
											sm: "48%",
											md: "48%",
											lg: "24%",
										}}
										flexGrow="1"
										flexShrink="1"
										boxShadow="-1px -1px 8px 1px rgba(211,211,211,0.68)"
										bgColor="#fff"
										p="3px"
										borderRadius="3px"
										key={product?.id}
									>
										<Box
											h="60%"
											onClick={() => {
												setIsOpenDetailsModal(true);
												setProductToShow(product);
											}}
											cursor="pointer"
										>
											<Image
												src={product?.image}
												alt=""
												w="100%"
												h="auto"
												maxHeight="100%"
												objectFit="contain"
											/>
										</Box>
										<Box h="40%" textAlign="center">
											<Text noOfLines={1} fontWeight="600" fontSize="small">
												{product?.name}
											</Text>
											<Text noOfLines={1} fontSize="small">
												KES. {product?.price.toLocaleString()}
											</Text>
											<Text noOfLines={1} fontSize="small">
												{product?.stockQuantity} in Stock
											</Text>
											<Flex gap="10px" justifyContent="center">
												<Tooltip label="Edit" hasArrow>
													<Button
														w="50px"
														h="20px"
														bgColor="#fff"
														onClick={() => handleEditClick(product)}
													>
														<MdEdit color="#418914" height="20px" />
													</Button>
												</Tooltip>
												<Tooltip label="Delete" hasArrow>
													<Button
														w="50px"
														h="20px"
														bgColor="#fff"
														onClick={() =>
															handleDeleteClick(
																product?.id,
																product?.name,
																product?.image
															)
														}
													>
														<RiDeleteBinLine color="#e74c3c" height="20px" />
													</Button>
												</Tooltip>
											</Flex>
										</Box>
									</Box>
								);
							})}
					</Flex>
					{msg === "Load More Products" && products?.length > 0 && (
						<Button mb="20px" onClick={() => setLoading(true)}>
							{msg}
						</Button>
					)}
				</>
			)}

			{isOpenDeleteModal && (
				<Modal
					isOpen={isOpenDeleteModal}
					onClose={() => {
						setIsOpenDeleteModal(false);
						setAlertInfo(false);
						setProductToDelete({});
					}}
				>
					<ModalOverlay />
					<ModalContent>
						{alertInfo ? (
							<Alert
								status="success"
								variant="subtle"
								flexDirection="column"
								alignItems="center"
								justifyContent="center"
								textAlign="center"
								height="300px"
							>
								<AlertIcon boxSize="40px" mr={0} />
								<AlertTitle mt={4} mb={1} fontSize="lg">
									Success
								</AlertTitle>
								<AlertDescription maxWidth="sm">
									You have successfully deleted{" "}
									<strong>{productToDelete?.productName}</strong>
								</AlertDescription>
								<Button
									onClick={() => {
										setIsOpenDeleteModal(false);
										setAlertInfo(false);
									}}
									mt="20px"
									bg="rgb(204, 204, 204)"
									color="#000"
									_hover={{
										background: "rgb(204, 204, 204)",
										color: "#000",
									}}
								>
									Close
								</Button>
							</Alert>
						) : (
							<>
								<ModalHeader color="#e74c3c">Confirm Deletion</ModalHeader>
								<ModalBody>
									<Text color="#e74c3c">
										Are you sure you want to delete{" "}
										<strong>{productToDelete?.productName}?</strong>
									</Text>
									<Image
										src={productToDelete?.productImage}
										alt=""
										w="50%"
										h="auto"
										maxHeight="100%"
										objectFit="contain"
										margin="auto"
										mt="10px"
									/>
								</ModalBody>
								<ModalFooter display="flex" justifyContent="space-between">
									<Button
										onClick={() => setIsOpenDeleteModal(false)}
										w="60px"
										bg="rgb(204, 204, 204)"
										color="#000"
										fontSize="15px"
										_hover={{
											background: "rgb(204, 204, 204)",
											color: "#000",
										}}
									>
										Cancel
									</Button>
									<Button
										onClick={handleConfirmDelete}
										w="60px"
										bg="#e74c3c"
										color="#fff"
										fontSize="15px"
										_hover={{
											background: "#e74c3c",
											color: "#fff",
										}}
									>
										{submitting ? <Spinner color="#fff" /> : "Delete"}
									</Button>
								</ModalFooter>
							</>
						)}
					</ModalContent>
				</Modal>
			)}

			{isOpenDetailsModal && (
				<Modal
					isOpen={isOpenDetailsModal}
					onClose={() => {
						setIsOpenDetailsModal(false);
						setProductToShow({});
					}}
					size="xl"
				>
					<ModalOverlay />
					<ModalContent>
						<ModalHeader color="#1890ff">{productToShow?.name}</ModalHeader>
						<ModalCloseButton />
						<ModalBody>
							<Image
								src={productToShow?.image}
								alt=""
								w="50%"
								h="auto"
								maxHeight="100%"
								objectFit="contain"
								margin="auto"
								mt="10px"
							/>
							<Flex
								gap="10px"
								mt="5px"
								mb="5px"
								borderTop="1px solid rgba(211,211,211,1)"
							>
								<Text w="20%" fontWeight="600" color="#1890ff">
									Description:
								</Text>
								<Text color="#000">{productToShow?.description}</Text>
							</Flex>
							<Flex
								gap="10px"
								mt="5px"
								mb="5px"
								borderTop="1px solid rgba(211,211,211,1)"
							>
								<Text w="20%" fontWeight="600" color="#1890ff">
									Category:
								</Text>
								<Text color="#000">{productToShow?.category}</Text>
							</Flex>
							<Flex
								gap="10px"
								mt="5px"
								mb="5px"
								borderTop="1px solid rgba(211,211,211,1)"
							>
								<Text w="20%" fontWeight="600" color="#1890ff">
									Price:
								</Text>
								<Text color="#000">
									KES. {productToShow?.price.toLocaleString()}
								</Text>
							</Flex>
							<Flex
								gap="10px"
								mt="5px"
								mb="5px"
								borderTop="1px solid rgba(211,211,211,1)"
							>
								<Text w="20%" fontWeight="600" color="#1890ff">
									SKU:
								</Text>
								<Text color="#000">{productToShow?.sku}</Text>
							</Flex>
							<Flex
								gap="10px"
								mt="5px"
								mb="5px"
								borderTop="1px solid rgba(211,211,211,1)"
							>
								<Text w="20%" fontWeight="600" color="#1890ff">
									Stock:
								</Text>
								<Text color="#000">{productToShow?.stockQuantity}</Text>
							</Flex>
							<Flex
								gap="10px"
								mt="5px"
								mb="5px"
								borderTop="1px solid rgba(211,211,211,1)"
							>
								<Text w="20%" fontWeight="600" color="#1890ff">
									Warranty:
								</Text>
								<Text color="#000">{productToShow?.warranty}</Text>
							</Flex>
							<Flex
								gap="10px"
								mt="5px"
								mb="5px"
								borderTop="1px solid rgba(211,211,211,1)"
							>
								<Text w="20%" fontWeight="600" color="#1890ff">
									Date Added:
								</Text>
								<Text color="#000">
									{moment(productToShow?.createdAt).format("DD/MM/YYYY")}
								</Text>
							</Flex>
						</ModalBody>
					</ModalContent>
				</Modal>
			)}

			{isOpenEditModal && (
				<Modal
					isOpen={isOpenEditModal}
					onClose={() => {
						setIsOpenEditModal(false);
						setProductToEdit({});
						setProductImg("");
						setEditError("");
						setAlertEditInfo(false);
						setProductImg("");
					}}
					size="xl"
				>
					<ModalOverlay />
					<ModalContent>
						{alertEditInfo ? (
							<Alert
								status="success"
								variant="subtle"
								flexDirection="column"
								alignItems="center"
								justifyContent="center"
								textAlign="center"
								height="300px"
							>
								<AlertIcon boxSize="40px" mr={0} />
								<AlertTitle mt={4} mb={1} fontSize="lg">
									Success
								</AlertTitle>
								<AlertDescription maxWidth="sm">
									You have successfully updated{" "}
									<strong>{productToEdit?.name}</strong>
								</AlertDescription>
								<Button
									onClick={() => {
										setIsOpenEditModal(false);
										setAlertEditInfo(false);
									}}
									mt="20px"
									bg="rgb(204, 204, 204)"
									color="#000"
									_hover={{
										background: "rgb(204, 204, 204)",
										color: "#000",
									}}
								>
									Close
								</Button>
							</Alert>
						) : (
							<>
								<ModalHeader color="#1890ff">Edit Form</ModalHeader>
								<ModalCloseButton />
								<ModalBody>
									<FormControl>
										<FormLabel color="#1890ff">Name</FormLabel>
										<input
											type="text"
											value={productToEdit?.name}
											onChange={(e) => {
												setProductToEdit({
													...productToEdit,
													name: e.target.value,
												});
											}}
											required
											style={{
												width: "100%",
												border: "none",
												borderRadius: "3px",
												outline: "none",
												padding: "2px",
												boxShadow: "0px 2px 5px 2px rgba(118, 118, 118, 0.3)",
												marginBottom: "10px",
											}}
										/>
										<FormLabel color="#1890ff">Description</FormLabel>
										<textarea
											type="text"
											value={productToEdit?.description}
											onChange={(e) => {
												setProductToEdit({
													...productToEdit,
													description: e.target.value,
												});
											}}
											required
											style={{
												width: "100%",
												border: "none",
												borderRadius: "3px",
												outline: "none",
												padding: "2px",
												boxShadow: "0px 2px 5px 2px rgba(118, 118, 118, 0.3)",
												marginBottom: "10px",
												height: "100px",
												maxLength: "1000",
											}}
										/>
										<FormLabel color="#1890ff">Category</FormLabel>
										<select
											type="text"
											value={productToEdit?.category}
											onChange={(e) =>
												setProductToEdit({
													...productToEdit,
													category: e.target.value,
												})
											}
											required
											style={{
												width: "100%",
												border: "none",
												borderRadius: "3px",
												outline: "none",
												padding: "2px",
												boxShadow: "0px 2px 5px 2px rgba(118, 118, 118, 0.3)",
												marginBottom: "10px",
											}}
										>
											{categories?.map((category) => {
												return (
													<option value={category.category} key={category.id}>
														{category.category}
													</option>
												);
											})}
										</select>
										<FormLabel color="#1890ff">Price</FormLabel>
										<input
											type="number"
											value={productToEdit?.price}
											onChange={(e) => {
												setProductToEdit({
													...productToEdit,
													price: e.target.value,
												});
											}}
											required
											style={{
												width: "100%",
												border: "none",
												borderRadius: "3px",
												outline: "none",
												padding: "2px",
												boxShadow: "0px 2px 5px 2px rgba(118, 118, 118, 0.3)",
												marginBottom: "10px",
											}}
										/>
										<FormLabel color="#1890ff">SKU</FormLabel>
										<input
											type="text"
											value={productToEdit?.sku}
											onChange={(e) => {
												setProductToEdit({
													...productToEdit,
													sku: e.target.value,
												});
											}}
											required
											style={{
												width: "100%",
												border: "none",
												borderRadius: "3px",
												outline: "none",
												padding: "2px",
												boxShadow: "0px 2px 5px 2px rgba(118, 118, 118, 0.3)",
												marginBottom: "10px",
											}}
										/>
										<FormLabel color="#1890ff">Stock</FormLabel>
										<input
											type="number"
											value={productToEdit?.stockQuantity}
											onChange={(e) => {
												setProductToEdit({
													...productToEdit,
													stockQuantity: e.target.value,
												});
											}}
											required
											style={{
												width: "100%",
												border: "none",
												borderRadius: "3px",
												outline: "none",
												padding: "2px",
												boxShadow: "0px 2px 5px 2px rgba(118, 118, 118, 0.3)",
												marginBottom: "10px",
											}}
										/>
										<FormLabel color="#1890ff">Warranty</FormLabel>
										<input
											type="text"
											value={productToEdit?.warranty}
											onChange={(e) => {
												setProductToEdit({
													...productToEdit,
													warranty: e.target.value,
												});
											}}
											required
											style={{
												width: "100%",
												border: "none",
												borderRadius: "3px",
												outline: "none",
												padding: "2px",
												boxShadow: "0px 2px 5px 2px rgba(118, 118, 118, 0.3)",
												marginBottom: "10px",
											}}
										/>
										<FormLabel color="#1890ff">Upload Image</FormLabel>
										<label htmlFor="file">
											<BsUpload
												htmlFor="file"
												style={{ cursor: "pointer", fontSize: "20px" }}
											/>
										</label>
										<input
											type="file"
											style={{ display: "none" }}
											id="file"
											onChange={(e) => handleImg(e)}
										/>
									</FormControl>
									<img
										src={
											productImg
												? URL.createObjectURL(productImg)
												: productToEdit?.image
										}
										alt=""
										style={{
											width: "50%",
											height: "auto",
											objectFit: "contain",
										}}
									/>
									{editError !== "" && (
										<Flex
											alignItems="center"
											position="relative"
											mb="10px"
											mt="10px"
										>
											<Alert status="error" w="100%">
												<AlertIcon />
												<AlertDescription>{editError}</AlertDescription>
											</Alert>
											<CloseButton
												alignSelf="center"
												position="absolute"
												right={1}
												onClick={() => setEditError("")}
											/>
										</Flex>
									)}
								</ModalBody>
								<ModalFooter display="flex" justifyContent="space-between">
									<Button
										onClick={() => {
											setIsOpenEditModal(false);
											setProductToEdit({});
											setProductImg("");
											setEditError("");
											setAlertEditInfo(false);
										}}
										w="60px"
										bg="rgb(204, 204, 204)"
										color="#000"
										fontSize="15px"
										_hover={{
											background: "rgb(204, 204, 204)",
											color: "#000",
										}}
									>
										Cancel
									</Button>
									<Button
										onClick={handleConfirmEdit}
										w="60px"
										bg="#418914"
										color="#fff"
										fontSize="15px"
										_hover={{
											background: "#418914",
											color: "#fff",
										}}
									>
										{submitting ? <Spinner color="#fff" /> : "Save"}
									</Button>
								</ModalFooter>
							</>
						)}
					</ModalContent>
				</Modal>
			)}

			{loading && <Loader />}
		</>
	);
}

export default ViewProducts;
