import React, { useEffect, useState } from "react";
import {
	Flex,
	Box,
	Circle,
	Text,
	Alert,
	AlertIcon,
	AlertDescription,
	CloseButton,
	Select,
} from "@chakra-ui/react";
import { HiOutlineCurrencyDollar } from "react-icons/hi";
import { RiShoppingBasketLine } from "react-icons/ri";
import { IoIosCart } from "react-icons/io";
import axios from "axios";
import url from "../ServerConnection/config";
import { Doughnut, Line, Pie } from "react-chartjs-2";
import {
	Chart as ChartJS,
	ArcElement,
	Tooltip,
	Legend,
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
} from "chart.js";
import randomColor from "randomcolor";
import CountUp from "react-countup";
import ScrollToTop from "./ScrollToTop";

ChartJS.register(
	ArcElement,
	Tooltip,
	Legend,
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title
);

function Dashboard() {
	const [sales, setSales] = useState(0);
	const [orders, setOrders] = useState(0);
	const [products, setProducts] = useState(0);
	const [productsPerCategory, setProductsPerCategory] = useState([]);
	const [error, setError] = useState("");
	const [salesData, setSalesData] = useState([]);
	const [salesPerProduct, setSalesPerProduct] = useState([]);
	const [year, setYear] = useState(new Date().getFullYear());
	let years = [];
	for (let i = new Date().getFullYear(); i >= 2022; i--) {
		years.push(i);
	}
	useEffect(() => {
		axios
			.get(`${url}/admin/dashboard/main`)
			.then((res) => {
				setOrders(res.data.orders);
				setProducts(res.data.products);
				setSales(res.data.sales);
			})
			.catch((err) => {
				setError(err.response.data);
			});
	}, []);

	useEffect(() => {
		axios
			.get(`${url}/admin/dashboard/products`)
			.then((res) => {
				setProductsPerCategory(res.data);
			})
			.catch((err) => {
				setError(err.response.data);
			});
	}, []);

	useEffect(() => {
		axios
			.post(`${url}/admin/dashboard/sales`, { year: year })
			.then((res) => {
				setSalesData(res.data);
			})
			.catch((err) => {
				setError(err.response.data);
			});
	}, [year]);

	useEffect(() => {
		axios
			.get(`${url}/admin/dashboard/mostsoldproducts`)
			.then((res) => {
				setSalesPerProduct(res.data);
			})
			.catch((err) => {
				setError(err.response.data);
			});
	}, []);

	let doughnutData = {
		labels: productsPerCategory?.map((item) => {
			return item.category;
		}),
		datasets: [
			{
				label: "Number of products",
				data: productsPerCategory?.map((item) => {
					return item.productCount;
				}),
				backgroundColor: productsPerCategory?.map(() => randomColor()),
				borderWidth: 0,
			},
		],
	};

	let lineGraphData = {
		labels: salesData?.map((item) => {
			return item.month;
		}),
		datasets: [
			{
				label: "Total Sales KES",
				data: salesData?.map((item) => {
					return item.total;
				}),
				backgroundColor: "#1890ff",
				borderColor: "#1890ff",
			},
		],
	};

	let pieData = {
		labels: salesPerProduct?.map((item) => {
			return item.productName?.name;
		}),
		datasets: [
			{
				label: "Orders per product",
				data: salesPerProduct?.map((item) => {
					return item.productCount;
				}),
				backgroundColor: salesPerProduct?.map(() => randomColor()),
				borderWidth: 0,
			},
		],
	};

	return (
		<div>
			<Text mb="10px" fontSize="large" fontWeight="700">
				Dashboard
			</Text>
			{error !== "" && (
				<Flex alignItems="center" position="relative" mb="10px">
					<Alert status="error" w="100%">
						<AlertIcon />
						<AlertDescription>{error}</AlertDescription>
					</Alert>
					<CloseButton
						alignSelf="center"
						position="absolute"
						right={1}
						onClick={() => setError("")}
					/>
				</Flex>
			)}
			<Flex
				h={{ base: "180px", md: "120px", lg: "60px" }}
				gap="20px"
				flexWrap={{ base: "wrap", md: "wrap", lg: "nowrap" }}
			>
				<Box
					flex="1"
					bg="#fff"
					boxShadow="-1px -1px 8px 1px rgba(211,211,211,0.68)"
					display="flex"
					gap="10px"
					alignItems="center"
					pl="10px"
					cursor="pointer"
					borderRadius="3px"
				>
					<Circle w="40px" h="40px" bg="#1890ff" color="#fff">
						<HiOutlineCurrencyDollar
							style={{ fontWeight: "700", fontSize: "20px" }}
						/>
					</Circle>
					<Box
						display="flex"
						flexDirection="column"
						fontSize={{ base: "small", md: "small", lg: "medium" }}
					>
						<Text fontWeight="600">Total Sales</Text>
						<Text>
							KES. <CountUp end={sales.toLocaleString()} redraw={true} />
						</Text>
					</Box>
				</Box>
				<Box
					flex="1"
					bg="#fff"
					boxShadow="-1px -1px 8px 1px rgba(211,211,211,0.68)"
					display="flex"
					gap="10px"
					alignItems="center"
					pl="10px"
					cursor="pointer"
					borderRadius="3px"
				>
					<Circle w="40px" h="40px" bg="#418914" color="#fff">
						<IoIosCart style={{ fontWeight: "700", fontSize: "25px" }} />
					</Circle>
					<Box
						display="flex"
						flexDirection="column"
						fontSize={{ base: "small", md: "small", lg: "medium" }}
					>
						<Text fontWeight="600">Total Orders</Text>
						<Text>
							<CountUp end={orders.toLocaleString()} redraw={true} />
						</Text>
					</Box>
				</Box>
				<Box
					flex="1"
					bg="#fff"
					boxShadow="-1px -1px 8px 1px rgba(211,211,211,0.68)"
					display="flex"
					gap="10px"
					alignItems="center"
					pl="10px"
					cursor="pointer"
					borderRadius="3px"
				>
					<Circle w="40px" h="40px" bg="#FFBF00" color="#fff">
						<RiShoppingBasketLine
							style={{ fontWeight: "700", fontSize: "25px" }}
						/>
					</Circle>
					<Box
						display="flex"
						flexDirection="column"
						fontSize={{ base: "small", md: "small", lg: "medium" }}
					>
						<Text fontWeight="600">Total Products</Text>
						<Text>
							<CountUp end={products.toLocaleString()} redraw={true} />
						</Text>
					</Box>
				</Box>
			</Flex>
			<Flex
				h={{ base: "700px", md: "700px", lg: "350px" }}
				gap="20px"
				mt="20px"
				flexWrap={{ base: "wrap", md: "wrap", lg: "nowrap" }}
				overflow="scroll"
				css={{
					"::-webkit-scrollbar": {
						w: 0,
					},
				}}
			>
				<Box
					display="flex"
					flexDirection="column"
					minHeight={{ base: "50%", md: "50%", lg: "100%" }}
					overflow="scroll"
					bg="#fff"
					minWidth={{ base: "100%", md: "100%", lg: "50%" }}
					borderRadius="3px"
					pl="10px"
					pt="10px"
					boxShadow="-1px -1px 8px 1px rgba(211,211,211,0.68)"
					css={{
						"&::-webkit-scrollbar": {
							display: "none",
						},
					}}
				>
					<Text fontWeight="600">Products Per Category</Text>
					<Box display="flex" alignItems="center" mt="10px">
						{productsPerCategory.length > 0 && (
							<Doughnut
								data={doughnutData}
								options={{
									responsive: true,
									plugins: {
										legend: {
											align: "start",
											position: "right",
											labels: {
												textAlign: "left",
												padding: 7,
											},
										},
									},
								}}
								style={{ maxHeight: "250px" }}
							/>
						)}
					</Box>
				</Box>
				<Box
					display="flex"
					flexDirection="column"
					minHeight={{ base: "50%", md: "50%", lg: "100%" }}
					overflow="scroll"
					bg="#fff"
					minWidth={{ base: "100%", md: "100%", lg: "50%" }}
					borderRadius="3px"
					pl="10px"
					pt="10px"
					boxShadow="-1px -1px 8px 1px rgba(211,211,211,0.68)"
					css={{
						"&::-webkit-scrollbar": {
							display: "none",
						},
					}}
				>
					<Flex justifyContent="space-between" pr="10px">
						<Text fontWeight="600">Sales Per Month</Text>
						<Select
							w="maxContent"
							h="25px"
							outline="none"
							border="none"
							onChange={(e) => setYear(e.target.value)}
						>
							{years.map((year) => {
								return <option value={year}>{year}</option>;
							})}
						</Select>
					</Flex>
					<Box>
						<Line
							data={lineGraphData}
							options={{
								responsive: true,
								plugins: {
									legend: {
										position: "top",
									},
									title: {
										display: true,
										text: `Sales Statistics for ${year}`,
									},
								},
							}}
						/>
					</Box>
				</Box>
			</Flex>
			<Box h="350px" mt="20px" mb="20px">
				<Box
					display="flex"
					flexDirection="column"
					h="300px%"
					bg="#fff"
					w="100%"
					borderRadius="3px"
					pl="10px"
					pt="10px"
					boxShadow="-1px -1px 8px 1px rgba(211,211,211,0.68)"
					overflow="scroll"
					css={{
						"&::-webkit-scrollbar": {
							display: "none",
						},
					}}
				>
					<Box>
						<Text fontWeight="600">Products Ordered</Text>
					</Box>
					<Box>
						<Pie
							data={pieData}
							options={{
								responsive: true,
								plugins: {
									legend: {
										align: "start",
										position: "left",
										labels: {
											textAlign: "left",
											padding: 7,
										},
									},
								},
							}}
							style={{ maxHeight: "250px" }}
						/>
					</Box>
				</Box>
			</Box>
			<ScrollToTop />
		</div>
	);
}

export default Dashboard;
